import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

import Row from 'antd/es/row';
import 'antd/es/row/style/css';
import Col from 'antd/es/col';
import 'antd/es/col/style/css';
import Layout from 'antd/es/layout';
import 'antd/es/layout/style/css';

export class Footer extends Component {
    render() {
        return (
            <Layout.Footer>
                {(this.props.width > 740) && <Row className="lvcc-footer">
                <Col span={8}>
                    <Link to="/" style={{"border-bottom":"none"}}> <span class="lvcc-logo" role="img" aria-label="Las Vegas Circus Center" style={{width:"180px"}}/> </Link>
                </Col>

                <Col span={8} className="lvcc-footer-about-us">
                    <h1>About Us</h1>
                    Las Vegas Circus Center <br />
                    6425 S Jones Blvd, Suite 102 <br />
                    Las Vegas, Nevada 89118 <br />
                    <a href="tel:1-833-324-7287">(833) 324-7287</a><br />
                    <a href="mailto:info@lasvegascircuscenter.com">info@lasvegascircuscenter.com</a><br />
                </Col>

                <Col span={8} className="lvcc-footer-important-links">
                    <h1>Important Links</h1>
                    <ul>
                        <li><Link to="/classes">Classes</Link></li>
                        <li><Link to="/pricing">Full Pricing</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                        <li><Link to="/policies">Safety Rules and Policies</Link></li>
                    </ul>
                </Col>
            </Row>}
            <div className="copyright">
              <SocialIcon url="https://www.instagram.com/lasvegascircuscenter" fgColor="#fff" style={{ height: 40, width: 40, margin: "2px" }} />
              <SocialIcon url="https://www.facebook.com/lasvegascircuscenter" fgColor="#fff" style={{ height: 40, width: 40, margin: "2px" }} />
              <SocialIcon url="https://www.yelp.com/biz/las-vegas-circus-center-las-vegas" fgColor="#fff" style={{ height: 40, width: 40, margin: "2px" }} />
              <SocialIcon url="https://www.twitter.com/lvcircuscenter" fgColor="#fff" style={{ height: 40, width: 40, margin: "2px" }} />
              <SocialIcon url="https://www.vimeo.com/lvcc" fgColor="#fff" style={{ height: 40, width: 40, margin: "2px" }} />
              <br/>
               ©{new Date().getFullYear()} by Las Vegas Circus Center.
            </div>
        </Layout.Footer>
        );
    }
}
