import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { Header } from './Header';
import Routes from './Routes';
import { Footer } from './Footer';
import './App.css';


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {width: window.innerWidth};
    }

    render() {
        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <ScrollToTop />
                <Header width={this.state.width} />
                <Routes width={this.state.width} />
                <Footer width={this.state.width} /> 
            </BrowserRouter>
        );
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    handleResize() {
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth});
        }
    }
}
