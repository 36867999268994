import React, { Component } from 'react';
import { MobileView, isIOS, isAndroid } from "react-device-detect";

export default class MobileDownload extends Component {
    render() {
        if(isIOS) {
          return (<MobileView>
                      <div>
                         <p>Enjoy easier scheduling with our app:</p>
<a href="https://itunes.apple.com/us/app/las-vegas-circus-center/id1440064090?mt=8" target="_blank" rel="noopener noreferrer"><img className="app-store-download-image" src="/images/apple-store-download-badge.png" alt="Download on the Apple Store" /></a>
                      </div>
                  </MobileView>);
        }
        if(isAndroid) {
          return (<MobileView>
                      <div>
                         <p>Enjoy easier scheduling with our app:</p>
<a href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.lasvegascircuscenter" target="_blank" rel="noopener noreferrer"><img className="app-store-download-image" src="/images/google-play-download-badge.png" alt="Get it on Google Play"/></a>
                      </div>
                  </MobileView>);
        }
        return null;
    }
}
