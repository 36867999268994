import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';


export default class Title extends Component {
    render() {
        return (<MetaTags>
                  <title>{this.props.title}</title>
                  <meta name="description" content={this.props.description||''} />
                  <meta property="og:title" content={this.props.title} />
                  <link rel="canonical" href={`${document.location.protocol}//${document.location.host}${document.location.pathname}`} /> </MetaTags>
        )
    }
}
