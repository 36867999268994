
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"4 min read","minutes":3.11,"time":186600,"words":622}
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom';
import Image from 'react-image-webp';
import Title from '../helpers/Title';
import LazyLoad from 'react-lazyload';
import Typography from 'antd/es/typography';
import 'antd/es/typography/style/css';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Title title="Las Vegas Circus Center" description="The premier circus training center of Las Vegas" mdxType="Title" /> 
    <p>{`We are the premier circus training center. Our unique facility offers programs for professionals as well as those just starting their exploration into the circus arts (children and adults!) `}<Link to="/contact-us" mdxType="Link">{`Contact Us`}</Link>{` with any questions you have and please come by for a tour anytime to find your entry point into the circus arts!  Keep us in mind for your next `}<Link to="/events/group-events" mdxType="Link">{`Group Event`}</Link>{`.`}</p>
    <div style={{
      backgroundColor: '#fea',
      display: props.width < 1000 ? 'none' : 'block'
    }}>
    <table><tbody>
        <tr>
            <td>
              <h1><strong>Beginners</strong></h1>
If you are new to the Circus Arts, then our <Link to="/class/circus-experience" mdxType="Link">Saturday Circus Experience</Link> is a great way to explore a variety of activities in a 90 minute session. Visit our <Link to="/class/circus-experience" mdxType="Link">Circus Experience</Link> page to learn more about this unique experience!
            </td>
            <td>
              <LazyLoad mdxType="LazyLoad">
                <Image src="/images/o/Aerial-Silks.jpg" webp="/images/o/Aerial-Silks.webp" alt="Aerial Silks Girl" title="Happy Girl on Aerial Silks" mdxType="Image" />
              </LazyLoad>
            </td>
        </tr>
        <tr>
            <td>
              <LazyLoad mdxType="LazyLoad">
                <Image src="/images/o/Parkour-Boy.jpg" webp="/images/o/Parkour-Boy.webp" alt="Parkour Boy" title="Determination of Boy doing Parkour" mdxType="Image" />
              </LazyLoad>
            </td>
            <td>
              <h1><strong>Class Offerings</strong></h1>
We offer classes in a number of popular classes such as <Link to="/class/aerial-arts" mdxType="Link">Aerial Arts</Link>, <Link to="/class/flying-trapeze-recreational" mdxType="Link">Flying Trapeze</Link>, <Link to="/class/trampoline" mdxType="Link">Trampoline/Trampwall</Link>, <Link to="/class/ninja-warrior" mdxType="Link">Ninja Warrior</Link>, <Link to="/class/parkour" mdxType="Link">Parkour</Link>, <Link to="/class/tumbling" mdxType="Link">Tumbling</Link>, and <Link to="/classes" mdxType="Link">more!</Link>  Check out our full listing on our <Link to="/classes" mdxType="Link">Class List</Link> page and <Link to="/schedule" mdxType="Link">Class Schedule</Link>.
            </td>
        </tr>
        <tr>
            <td>
              <h1><strong>Have Experience?</strong></h1>
If you have experience or already know where you want to start, we recommend checking our current <Link to="/schedule" mdxType="Link">Class Schedule</Link> for a list of upcoming classes and our <Link to="/open-gym" mdxType="Link">Open Gym</Link> times and pricing.
            </td>
            <td>
              <LazyLoad mdxType="LazyLoad">
                <Image src="/images/o/Static-Trapeze-Woman.jpg" webp="/images/o/Static-Trapeze-Woman.webp" alt="Static Trapeze" title="Happy Woman on Static Trapeze" mdxType="Image" />
              </LazyLoad>
            </td>
        </tr>
        <tr>
            <td>
              <LazyLoad mdxType="LazyLoad">
                <Image src="/images/o/Flying-Trapeze-Girl.jpg" webp="/images/o/Flying-Trapeze-Girl.webp" alt="Flying Trapeze Girl" title="Happy Girl on Flying Trapeze" mdxType="Image" />
              </LazyLoad>
            </td>
            <td>
              <h1><strong>All Levels Welcome!</strong></h1>
Whatever you struggle with: can't do a sit-up, can't touch your toes, or have a healthy fear of heights - we'll work with you to achieve greater strength, flexibility, and bravery. Any age or level welcome!
            </td>
        </tr>
    </tbody></table>
    </div>
    <div style={{
      backgroundColor: '#fea',
      display: props.width < 1000 ? 'block' : 'none'
    }}>
    <table><tbody>
        <tr>
            <td>
              <h1><strong>Beginners</strong></h1>
If you are new to the Circus Arts, then our <Link to="/class/circus-experience" mdxType="Link">Saturday Circus Experience</Link> is a great way to explore a variety of activities in a 90 minute session. Visit our <Link to="/class/circus-experience" mdxType="Link">Circus Experience</Link> page to learn more about this unique experience!
            </td>
        </tr>
        <tr>
            <td>
              <LazyLoad mdxType="LazyLoad">
                <Image src="/images/o/Aerial-Silks.jpg" webp="/images/o/Aerial-Silks.webp" alt="Aerial Silks Girl" title="Happy Girl on Aerial Silks" mdxType="Image" />
              </LazyLoad>
            </td>
        </tr>
        <tr>
            <td>
              <h1><strong>Class Offerings</strong></h1>
We offer classes in a number of popular classes such as <Link to="/class/aerial-arts" mdxType="Link">Aerial Arts</Link>, <Link to="/class/flying-trapeze-recreational" mdxType="Link">Flying Trapeze</Link>, <Link to="/class/trampoline" mdxType="Link">Trampoline/Trampwall</Link>, <Link to="/class/ninja-warrior" mdxType="Link">Ninja Warrior</Link>, <Link to="/class/parkour" mdxType="Link">Parkour</Link>, <Link to="/class/tumbling" mdxType="Link">Tumbling</Link>, and <Link to="/classes" mdxType="Link">more!</Link>  Check out our full listing on our <Link to="/classes" mdxType="Link">Class List</Link> page and <Link to="/schedule" mdxType="Link">Class Schedule</Link>.
            </td>
        </tr>
        <tr>
            <td>
              <LazyLoad mdxType="LazyLoad">
                <Image src="/images/o/Parkour-Boy.jpg" webp="/images/o/Parkour-Boy.webp" alt="Parkour Boy" title="Determination of Boy doing Parkour" mdxType="Image" />
              </LazyLoad>
            </td>
        </tr>
        <tr>
            <td>
              <h1><strong>Have Experience?</strong></h1>
If you have experience or already know where you want to start, we recommend checking our current <Link to="/schedule" mdxType="Link">Class Schedule</Link> for a list of upcoming classes and our <Link to="/open-gym" mdxType="Link">Open Gym</Link> times and pricing.
            </td>
        </tr>
        <tr>
            <td>
              <LazyLoad mdxType="LazyLoad">
                <Image src="/images/o/Static-Trapeze-Woman.jpg" webp="/images/o/Static-Trapeze-Woman.webp" alt="Static Trapeze" title="Happy Woman on Static Trapeze" mdxType="Image" />
              </LazyLoad>
            </td>
        </tr>
        <tr>
            <td>
              <h1><strong>All Levels Welcome!</strong></h1>
Whatever you struggle with: can't do a sit-up, can't touch your toes, or have a healthy fear of heights - we'll work with you to achieve greater strength, flexibility, and bravery. Any age or level welcome!
            </td>
        </tr>
        <tr>
            <td>
              <LazyLoad mdxType="LazyLoad">
                <Image src="/images/o/Flying-Trapeze-Girl.jpg" webp="/images/o/Flying-Trapeze-Girl.webp" alt="Flying Trapeze Girl" title="Happy Girl on Flying Trapeze" mdxType="Image" />
              </LazyLoad>
            </td>
        </tr>
    </tbody></table>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

