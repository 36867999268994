import { withRouter } from "react-router-dom";

import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

class ScrollToTop extends React.Component {
    componentDidMount() {
        // initialize the google analytics component just once.
        ReactGA.initialize('UA-126741108-1');

        // update for the first time
        ReactGA.pageview(this.props.location.pathname);

        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };

        const advancedMatching = {}; //{ em: 'some@email.com' };
        ReactPixel.init('708180779534881', advancedMatching, options);

        ReactPixel.pageView(this.props.location.pathname);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // register this page in the page view
            ReactGA.pageview(this.props.location.pathname);
            ReactPixel.pageView(this.props.location.pathname);
            window.scrollTo(0, 0);
        }
    }

    render() {
        return null;
    }
}


export default withRouter(ScrollToTop);
