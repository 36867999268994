import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { Landing } from './Landing';

const Privates = loadable(() => import( '!babel-loader!mdx-loader!./content/Privates.mdx'));

const Workshops = loadable(() => import( '!babel-loader!mdx-loader!./content/events/Workshops.mdx'));
const Shows = loadable(() => import( '!babel-loader!mdx-loader!./content/events/Shows.mdx'));
const AfterDark = loadable(() => import( '!babel-loader!mdx-loader!./content/events/shows/AfterDark.mdx'));
const Auditions = loadable(() => import( '!babel-loader!mdx-loader!./content/events/Auditions.mdx'));
//const BirthdayParties = loadable(() => import( '!babel-loader!mdx-loader!./content/events/BirthdayParties.mdx'));
const GroupEvents = loadable(() => import( '!babel-loader!mdx-loader!./content/events/GroupEvents.mdx'));

const Camps = loadable(() => import( '!babel-loader!mdx-loader!./content/camps/Camps.mdx'));
const AerialArtsCamp = loadable(() => import( '!babel-loader!mdx-loader!./content/camps/AerialArts.mdx'));
const WorldOfCircusCamp = loadable(() => import( '!babel-loader!mdx-loader!./content/camps/WorldOfCircus.mdx'));
const CircusNinjaCamp = loadable(() => import( '!babel-loader!mdx-loader!./content/camps/CircusNinja.mdx'));
const StuntCamp = loadable(() => import( '!babel-loader!mdx-loader!./content/camps/StuntCamp.mdx'));

const Announcement = loadable(() => import( '!babel-loader!mdx-loader!./content/Announcement.mdx'));
const ClassSchedule = loadable(() => import( '!babel-loader!mdx-loader!./content/ClassSchedule.mdx'));
const AboutUs = loadable(() => import( '!babel-loader!mdx-loader!./content/AboutUs.mdx'));
const FeaturedArt = loadable(() => import( '!babel-loader!mdx-loader!./content/FeaturedArt.mdx'));
const NotFound = loadable(() => import( '!babel-loader!mdx-loader!./content/NotFound.mdx'));
const Newsletter = loadable(() => import( '!babel-loader!mdx-loader!./content/Newsletter.mdx'));

const RegisterThankYou = loadable(() => import( '!babel-loader!mdx-loader!./content/RegisterThankYou.mdx'));
const BookingThankYou = loadable(() => import( '!babel-loader!mdx-loader!./content/BookingThankYou.mdx'));

const Blog = loadable(() => import( '!babel-loader!mdx-loader!./blog/Blog.mdx'));
const PathToPerformance = loadable(() => import( '!babel-loader!mdx-loader!./blog/PathToPerformance.mdx'));
const PathToPerformanceShaneDoi = loadable(() => import( '!babel-loader!mdx-loader!./blog/PathToPerformance-ShaneDoi.mdx'));
const PathToPerformanceJulieMarshall = loadable(() => import( '!babel-loader!mdx-loader!./blog/PathToPerformance-JulieMarshall.mdx'));
const PathToPerformanceAlioFan = loadable(() => import( '!babel-loader!mdx-loader!./blog/PathToPerformance-AlioFan.mdx'));
const PathToPerformanceTaylorStevens = loadable(() => import( '!babel-loader!mdx-loader!./blog/PathToPerformance-TaylorStevens.mdx'));

const AerialArts = loadable(() => import( '!babel-loader!mdx-loader!./content/class/AerialArts.mdx'));
const AerialSilks = loadable(() => import( '!babel-loader!mdx-loader!./content/class/AerialSilks.mdx'));
const IntroToAerial = loadable(() => import( '!babel-loader!mdx-loader!./content/class/IntroToAerial.mdx'));
const AerialHammock = loadable(() => import( '!babel-loader!mdx-loader!./content/class/AerialHammock.mdx'));
const AerialLyra = loadable(() => import( '!babel-loader!mdx-loader!./content/class/AerialLyra.mdx'));
const AerialRope = loadable(() => import( '!babel-loader!mdx-loader!./content/class/AerialRope.mdx'));
const AerialStraps = loadable(() => import( '!babel-loader!mdx-loader!./content/class/AerialStraps.mdx'));
const AerialPole = loadable(() => import( '!babel-loader!mdx-loader!./content/class/AerialPole.mdx'));
const AerialFitness = loadable(() => import( '!babel-loader!mdx-loader!./content/class/AerialFitness.mdx'));
const SpanishWeb = loadable(() => import( '!babel-loader!mdx-loader!./content/class/SpanishWeb.mdx'));
const StaticTrapeze = loadable(() => import( '!babel-loader!mdx-loader!./content/class/StaticTrapeze.mdx'));
const DanceTrapeze = loadable(() => import( '!babel-loader!mdx-loader!./content/class/DanceTrapeze.mdx'));
const Dance = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Dance.mdx'));
const RecreationalFlyingTrapeze = loadable(() => import( '!babel-loader!mdx-loader!./content/class/RecreationalFlyingTrapeze.mdx'));
const ProfessionalFlyingTrapeze = loadable(() => import( '!babel-loader!mdx-loader!./content/class/ProfessionalFlyingTrapeze.mdx'));
const ProfessionalTeeterboard = loadable(() => import( '!babel-loader!mdx-loader!./content/class/ProfessionalTeeterboard.mdx'));
const Tumbling = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Tumbling.mdx'));
const Trampoline = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Trampoline.mdx'));
const HandBalancing = loadable(() => import( '!babel-loader!mdx-loader!./content/class/HandBalancing.mdx'));
const NinjaWarrior = loadable(() => import( '!babel-loader!mdx-loader!./content/class/NinjaWarrior.mdx'));
const Parkour = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Parkour.mdx'));
const Tricking = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Tricking.mdx'));
const Unicycle = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Unicycle.mdx'));
const CyrWheel = loadable(() => import( '!babel-loader!mdx-loader!./content/class/CyrWheel.mdx'));
const Juggling = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Juggling.mdx'));
const Flexibility = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Flexibility.mdx'));
const ChinesePole = loadable(() => import( '!babel-loader!mdx-loader!./content/class/ChinesePole.mdx'));
const ClowningCharacter = loadable(() => import( '!babel-loader!mdx-loader!./content/class/ClowningCharacter.mdx'));
const ActingForPerformance = loadable(() => import( '!babel-loader!mdx-loader!./content/class/ActingForPerformance.mdx'));
const Skating = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Skating.mdx'));
const CircusExperience = loadable(() => import( '!babel-loader!mdx-loader!./content/class/CircusExperience.mdx'));
const StrengthAndConditioning = loadable(() => import( '!babel-loader!mdx-loader!./content/class/StrengthAndConditioning.mdx'));
const Capoeira = loadable(() => import( '!babel-loader!mdx-loader!./content/class/Capoeira.mdx'));
const Classes = loadable(() => import( '!babel-loader!mdx-loader!./content/Classes.mdx'));
const OnlineClasses = loadable(() => import( '!babel-loader!mdx-loader!./content/OnlineClasses.mdx'));
const Appointments = loadable(() => import( '!babel-loader!mdx-loader!./content/Appointments.mdx'));
//const CharityOfTheMonth = loadable(() => import( '!babel-loader!mdx-loader!./content/charity/CharityOfTheMonth.mdx'));

const JamesBullough = loadable(() => import( '!babel-loader!mdx-loader!./content/JamesBullough.mdx'));
const RickyWatts = loadable(() => import( '!babel-loader!mdx-loader!./content/RickyWatts.mdx'));

const PressKit = loadable(() => import( '!babel-loader!mdx-loader!./content/PressKit.mdx'));
const Hello = loadable(() => import( '!babel-loader!mdx-loader!./content/Hello.mdx'));
const LiveEvent = loadable(() => import( '!babel-loader!mdx-loader!./content/LiveEvent.mdx'));
const TestEvent = loadable(() => import( '!babel-loader!mdx-loader!./content/TestEvent.mdx'));

const Scholarship = loadable(() => import( '!babel-loader!mdx-loader!./content/Scholarship.mdx'));
const SpecialEventSignup = loadable(() => import( '!babel-loader!mdx-loader!./content/SpecialEventSignup.mdx'));

const Beginners = loadable(() => import('!babel-loader!mdx-loader!./content/Beginners.mdx'));
const OpenGym = loadable(() => import('!babel-loader!mdx-loader!./content/OpenGym.mdx'));
const Instructors = loadable(() => import('!babel-loader!mdx-loader!./content/Instructors.mdx'));
const Kids = loadable(() => import('!babel-loader!mdx-loader!./content/Kids.mdx'));
const Pricing = loadable(() => import('!babel-loader!mdx-loader!./content/Pricing.mdx'));
const Policies = loadable(() => import('!babel-loader!mdx-loader!./content/Policies.mdx'));
const MindbodyRegistration = loadable(() => import('!babel-loader!mdx-loader!./content/MindbodyRegistration.mdx'));

const Jobs = loadable(() => import('./Jobs'));

const Vote = loadable(() => import('./vote/Vote'));
const Tally = loadable(() => import('./vote/Tally'));

export default function Routes(props) {
    return (
        <div style={{minHeight: 700}}>
            <Switch>
                <Route path="/" exact render={() => <Landing width={props.width} />} />
                <Route path="/beginners" render={page(<Beginners />)} />
                <Route path="/kids" render={page(<Kids />)} />
                <Route path="/classes" render={page(<Classes />)} />
                <Route path="/online-classes" render={page(<OnlineClasses />)} />
                <Route path="/appointments" render={page(<Appointments />)} />
                <Route path="/weekly-schedule" render={redirect('/schedule')} />
                <Route path="/schedule" render={page(<ClassSchedule />)} />
                <Route path="/pricing" render={page(<Pricing />)} />

                <Route 
                    path="/employee-check" 
                    component={() => { 
                	     window.location.href = 'https://airtable.com/shrAGUmqFaEHbewW1';
                	     return null;
                    }} 
                />

                <Route 
                    path="/register"
                    component={() => { 
                	     window.location.href = 'https://www.waiverking.com/print/27137';
                	     return null;
                    }} 
                />
                <Route path="/legacy-register" render={page(<MindbodyRegistration />)} />

                <Route path="/workshops" render={redirect('/events/workshops')} />
                <Route path="/events/shows/afterdark" render={page(<AfterDark />)} />
                <Route path="/events/shows" render={page(<Shows />)} />
                <Route path="/events/group-events" render={page(<GroupEvents />)} />
                <Route path="/events/workshops" render={page(<Workshops />)} />
                <Route path="/events/auditions" render={page(<Auditions />)} />


                <Route path="/camps/aerial-arts" render={page(<AerialArtsCamp />)} />
                <Route path="/camps/world-of-circus" render={page(<WorldOfCircusCamp />)} />
                <Route path="/camps/circus-ninja" render={page(<CircusNinjaCamp />)} />
                <Route path="/camps/stunt" render={page(<StuntCamp />)} />
                <Route path="/camps" render={page(<Camps />)} />

                <Route path="/policies" render={page(<Policies />)} />
                <Route path="/privates" render={page(<Privates />)} />
                <Route path="/contact-us" render={redirect('/about-us')} />
                <Route path="/announcement" render={page(<Announcement />)} />
                <Route path="/about-us" render={page(<AboutUs />)} />
                <Route path="/instructors" render={page(<Instructors />)} />
                <Route path="/jobs" component={Jobs} />
                <Route path="/newsletter" render={page(<Newsletter />)} />
                <Route path="/open-gym" render={page(<OpenGym />)} />
                <Route path="/open-gym-training" render={redirect('/open-gym')} />
                <Route path="/register-thank-you" render={page(<RegisterThankYou />)} />
                <Route path="/booking-thank-you" render={page(<BookingThankYou />)} />

                <Route path="/blog/path-to-performance/shane-doi" render={page(<PathToPerformanceShaneDoi />)} />
                <Route path="/blog/path-to-performance/julie-marshall" render={page(<PathToPerformanceJulieMarshall />)} />
                <Route path="/blog/path-to-performance/alio-fan" render={page(<PathToPerformanceAlioFan />)} />
                <Route path="/blog/path-to-performance/taylor-stevens" render={page(<PathToPerformanceTaylorStevens />)} />
                <Route path="/blog/path-to-performance" render={page(<PathToPerformance />)} />
                <Route path="/blog/" render={page(<Blog />)} />


                <Route path="/class/aerial-arts" render={page(<AerialArts />)} />
                <Route path="/class/aerial-silks" render={page(<AerialSilks />)} />
                <Route path="/class/intro-to-aerial" render={page(<IntroToAerial />)} />
                <Route path="/class/aerial-hammock" render={page(<AerialHammock />)} />
                <Route path="/class/aerial-lyra" render={page(<AerialLyra />)} />
                <Route path="/class/aerial-rope" render={page(<AerialRope />)} />
                <Route path="/class/aerial-straps" render={page(<AerialStraps />)} />
                <Route path="/class/aerial-pole" render={page(<AerialPole />)} />
                <Route path="/class/aerial-fitness" render={page(<AerialFitness />)} />
                <Route path="/class/spanish-web" render={page(<SpanishWeb />)} />
                <Route path="/class/static-trapeze" render={page(<StaticTrapeze />)} />
                <Route path="/class/dance-trapeze" render={page(<DanceTrapeze />)} />
                <Route path="/class/dance" render={page(<Dance />)} />
                <Route path="/class/flying-trapeze-recreational" render={page(<RecreationalFlyingTrapeze />)} />
                <Route path="/class/flying-trapeze-professional" render={page(<ProfessionalFlyingTrapeze />)} />

                <Route path="/class/teeterboard-professional" render={page(<ProfessionalTeeterboard />)} />

                <Route path="/class/tumbling" render={page(<Tumbling />)} />
                <Route path="/class/trampoline" render={page(<Trampoline />)} />
                <Route path="/class/hand-balancing" render={page(<HandBalancing />)} />
                <Route path="/class/ninja-warrior" render={page(<NinjaWarrior />)} />
                <Route path="/class/parkour" render={page(<Parkour />)} />
                <Route path="/class/tricking" render={page(<Tricking />)} />
                <Route path="/class/unicycle" render={page(<Unicycle />)} />
                <Route path="/class/cyr-wheel" render={page(<CyrWheel />)} />
                <Route path="/class/juggling" render={page(<Juggling />)} />
                <Route path="/contortion" render={redirect('/class/contortion')} />
                <Route path="/class/contortion" render={page(<Flexibility />)} />
                <Route path="/class/flexibility" render={page(<Flexibility />)} />
                <Route path="/class/chinese-pole" render={page(<ChinesePole />)} />
                <Route path="/class/clowning-character" render={page(<ClowningCharacter />)} />
                <Route path="/class/acting-for-performance" render={page(<ActingForPerformance />)} />
                <Route path="/class/circus-experience" render={page(<CircusExperience />)} />
                <Route path="/class/skating" render={page(<Skating />)} />
                <Route path="/circus-experience" render={redirect('/class/circus-experience')} />
                <Route path="/class/circus-experience" render={page(<CircusExperience />)} />
                <Route path="/class/conditioning" render={page(<StrengthAndConditioning />)} />
                <Route path="/class/capoeira" render={page(<Capoeira />)} />


                <Route path="/art/featured" render={page(<FeaturedArt />)} />
                <Route path="/art/james-bullough" render={page(<JamesBullough />)} />
                <Route path="/art/ricky-watts" render={page(<RickyWatts />)} />

                <Route path="/press-kit" render={page(<PressKit />)} />
                <Route path="/hello" render={page(<Hello />)} />

                {/* <Route path="/live" render={page(<LiveEvent />)} /> */}
                <Route path="/live" render={() => <div className="page"><LiveEvent width={props.width} /></div>} />
                <Route path="/test" render={() => <div className="page"><TestEvent width={props.width} /></div>} />

                <Route path="/scholarship" exact render={page(<Scholarship />)} />
                <Route path="/sign-me-up" render={page(<SpecialEventSignup />)} />

                <Route path="/vote/tally" exact render={page(<Tally />)} />
                <Route path="/vote" exact render={page(<Vote />)} />

                <Route component={page(<NotFound />)} />
            </Switch>
            <div id="healcode-container" />
        </div>
    );
}


function page(component) {
    return props => (<div className="page">{component}</div>);
}
function redirect(uri) {
    return props => (<Redirect to={uri} />);
}
