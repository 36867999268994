import React, { Component } from 'react';
import LandingContent from '!babel-loader!mdx-loader!./content/Landing.mdx';

export class Landing extends Component {
    render() {
        return (
            <div className="landing">
                <LandingContent width={this.props.width} />
            </div>
        );
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.width < 1000 && nextProps.width >= 1000) 
            || (this.props.width >= 1000 && nextProps.width < 1000);
    }
}
